/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import {
  Toast, ToastContainer,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function FormSubmission({ toast }) {
  const [show, setShow] = useState(toast);
  useEffect(() => {
    setShow(toast);
  }, [toast]);
  return (
    <ToastContainer containerPosition="fixed" position="bottom-end" className="me-md-4 mb-4">
      <Toast show={show} className="form-submission-toast">
        <Toast.Body>
        <div onClick={() => setShow(false)} className="form-submission-toast-button text-end"><FontAwesomeIcon icon="fa-solid fa-xmark" /></div>
          <div className="form-submission-toast-content">Form Submitted! Our team will contact you soon.</div>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
}
